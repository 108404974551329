import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
import { KcPage } from "./kc.gen";
import "./resources/scss/index.scss";

// The following block can be uncommented to test a specific page with `yarn dev`
// Don't forget to comment back or your bundle size will increase

/*
import { getKcContextMock } from "./login/KcPageStory";

if (process.env.NODE_ENV === "development") {
  window.kcContext = getKcContextMock({
    pageId: "login.ftl",
    overrides: {},
  });
}
*/

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    {!window.kcContext ? (
      <h1>CONTACT ID Management</h1>
    ) : (
      <KcPage kcContext={window.kcContext} />
    )}
  </StrictMode>,
);
